/* eslint-disable jsx-a11y/anchor-is-valid */
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React, { useState } from 'react'
import { CUSTOMER_DETAIL } from '../urls'
import { useDeleteRequest, usePutRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { useMessage } from '../hooks/message'
import { checkPermission as cp } from '../utils/auth'
import CustomerMeeting from './CustomerMeeting'
import StudentPayment from './StudentPayment'
import Loader from './common/Loader'
import StudentMessage from './StudentMessage'
import { map } from "lodash";

export default function CustomerActions({
    customer,
    onDelete,
    onTypeChanged,
    customers,
    onClickUpdate,
    className,
    paramsType,
}) {
    const [showDropdown, setShowDropdown] = useState(false)
    const url = CUSTOMER_DETAIL.replace('{id}', customer.id)
    const customerDelete = useDeleteRequest({ url })
    const customerUpdate = usePutRequest({ url })
    const [showMessage] = useMessage()

    const [showMeetingModal, hideMeetingModal] = useModal(
        <CustomerMeeting onSuccess={() => hideMeetingModal()} customer={customer} />,
    )

    const [showPaymentModal, hidePaymentModal] = useModal(
        <StudentPayment onSuccess={() => hidePaymentModal()} customer={customer} />,
    )

    const [showMessageModal, hideMessageModal] = useModal(
        <StudentMessage onSuccess={() => hideMessageModal()} customer={customer} />,
    )

    async function deleteCustomer() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const { error } = await customerDelete.request()

            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }

            onDelete(customer.id)
        }
    }

    async function changeType(type) {
        if (global.confirm('Вы действительно хотите изменить статус клиента?')) {
            const { response, error, success } = await customerUpdate.request({ data: {
                ...customer,
                advert: customer.advert.id,
                seller: customer.seller.id,
                    subject: map(customer.subject, 'id'),
                    teacher: map(customer.teacher, 'id'),
                type,
                    student_image: null,

                }
            })

            if (error) {
                showMessage(error?.data?.detail || "Ошибка", 'is-danger')
            }
            if(success){
                setShowDropdown(false)
                customers.request()
                // onUpdate(customer.id, paramsType === '' ? response : {})
            }

        }
    }

    return (
        <div onMouseLeave={() => setShowDropdown(false)}
            onClick={(event) => event.stopPropagation()}
            className={cn(
                'dropdown is-right',
                { 'is-active': showDropdown },
                css(styles.more),
                className,
            )}>

            <div className="dropdown-trigger">
                <button className={cn('button is-white', css(styles.button))}
                    onClick={() => setShowDropdown(!showDropdown)}>

                    <i className="icon ion-md-more" />
                </button>
            </div>

            <div className="dropdown-menu">
                <div className="dropdown-content">
                    {cp('academy.change_customer') ? (
                        <a className="dropdown-item" onClick={onClickUpdate}>
                            <i className="icon ion-md-create" />
                            Изменить
                        </a>
                    ) : null}

                    {cp('academy.delete_customer') ? (
                        <a className="dropdown-item" onClick={deleteCustomer}>
                            <i className="icon ion-md-trash" />
                            Удалить
                        </a>
                    ) : null}

                    {cp('task.add_task') ? (
                        <a className="dropdown-item" onClick={showMeetingModal}>
                            <i className="icon ion-md-alarm" />
                            Встреча / Звонок
                        </a>
                    ) : null}

                    {cp('finance.add_student_payment') ? (
                        <a className="dropdown-item" onClick={showPaymentModal}>
                            <i className="icon ion-md-cash" />
                            Оплата
                        </a>
                    ) : null}

                    <a href={`tel:+${customer.phone}`} className="dropdown-item is-white">
                        <i className="icon ion-md-call" />
                        Позвонить
                    </a>
                    <a onClick={showMessageModal} className="dropdown-item is-white">
                        <i className="icon ion-md-mail" />
                        Сообщение
                    </a>

                    <hr className="dropdown-divider" />

                    {cp('academy.change_customer') && customer.type === 'active' ? (
                        <a className="dropdown-item" onClick={() => changeType('archived')}>
                            {customerUpdate.loading ? <Loader /> : <i className="icon ion-md-filing" />}
                            Архивировать
                        </a>
                    ) : null}

                    {cp('academy.change_customer') && customer.type === 'active' ? (
                        <a className="dropdown-item" onClick={() => changeType('rejected')}>
                            {customerUpdate.loading ? <Loader /> : <i className="icon ion-md-warning" />}
                            Отказал
                        </a>
                    ) : null}

                    {cp('academy.change_customer') && customer.type !== 'active' ? (
                        <a className="dropdown-item" onClick={() => changeType('active')}>
                            {customerUpdate.loading ? <Loader /> : <i className="icon ion-md-undo" />}
                            {customer.type === 'rejected' ? 'Активировать' : 'Воcстановить'}
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: 'transparent',
        ':hover': { backgroundColor: '#fcfcfc' },
    },
    more: {

    },
})
