import React, { Fragment } from 'react'
import cn from 'classnames'
import { Droppable } from 'react-beautiful-dnd'
import { css, StyleSheet } from 'aphrodite'
import { useModal } from '../hooks/modal'
import CustomerBoardCard from './CustomerBoardCard'
import CustomerCreate from './CustomerCreate'
import { useInfiniteScroll } from '../hooks/request'
import { CUSTOMER_LIST } from '../urls'
import { useQueryParams } from '../hooks/queryString'


export default function CustomerBoardColumn({
    columnKey,
    column,
    onDelete,
    onUpdate,
    onCreate,
    lineColor,
    showLabels,
    customersAll,
    type,
    customers,
}) {
    const params = useQueryParams()
    const customer = useInfiniteScroll({url:CUSTOMER_LIST, params:{...params, state:column.id, }})
    const customerList = customer.response?.results || []
    const [showCreateModal, hideCreateModal] = useModal(
        <CustomerCreate
            state={columnKey}
            onSuccess={(data) => {
                onCreate({
                    count: customersAll.response.count + 1,
                    results: [data, ...customersAll.response.results],
                })
                hideCreateModal()
            }}
            onCancel={() => hideCreateModal()} />,
    )

    return (
        <Fragment>
            <div className={css(styles.header)}>
                <span className={css(styles.headerTitle)}>
                    {column.title} &nbsp;
                    <span className={css(styles.count)}>({customer.response?.count || 0})</span>
                </span>

                <hr style={{ background: lineColor }} className={css(styles.line)} />
            </div>

            <div onClick={showCreateModal} className={cn('box has-text-success', css(styles.createButton))}>
                <i className="icon ion-md-add" />
                Добавить
            </div>

            <Droppable droppableId={String(columnKey)}>
                {(provider, snapshot) => (
                    <div ref={provider.innerRef} className={css(snapshot.isDraggingOver && styles.active)} style={{height:'100%'}}>
                        {customerList.map((customer, index) => (
                            <CustomerBoardCard
                                customers={customers}
                                onDelete={onDelete}
                                customer={customer}
                                showLabels={showLabels}
                                index={index}
                                onUpdate={onUpdate}
                                key={customer.id}
                                type={type} />
                        ))}
                        {provider.placeholder}
                    </div>
                )}
            </Droppable>
        </Fragment>
    )
}

const styles = StyleSheet.create({
    createButton: {
        whiteSpace: 'nowrap',
        marginBottom: '0.5rem',
        borderRadius: '3px',
        cursor: 'pointer',
        padding: '0.7rem',
        ':hover': {
            background: '#fbf9f9',
        },
    },
    active: {
        background: '#e8e8e8',
        borderRadius: '3px',
    },
    line: {
        margin: '0.5rem 0 1rem',
    },
    count: {
        color: '#989797',
    },
    header: {
        background: 'whitesmoke',
        margin: '0 -0.6rem',
        padding: '0 0.6rem',
        position: 'sticky',
        top: '0',
        whiteSpace: 'nowrap',
        zIndex: 1,
    },
    headerTitle: {
        marginLeft: '0.4rem',
    },
})
