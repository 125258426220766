import React from 'react'
import { usePostRequest } from '../hooks/request'
import { STUDENT_LIST } from '../urls'
import StudentForm from './StudentForm'


export default function StudentCreate({ customer, onSuccess, onCancel }) {
    const createStudent = usePostRequest({ url: STUDENT_LIST })


    async function onSubmit(data) {
        const { response } = await createStudent.request({ data: { ...data, customer: customer.id } })
        customer.students.push(response)
        onSuccess()
    }

    return (
        <div>
            <b>В какую группу вы хотите принят?</b><br /><br />

            <StudentForm
                loading={createStudent.loading}
                customer={customer}
                onSubmit={onSubmit}
                onCancel={onCancel} />
        </div>
    )
}
