import React from 'react'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { DragDropContext } from 'react-beautiful-dnd'
import { usePutRequest } from '../hooks/request'
import { CUSTOMER_MOVE } from '../urls'
import CustomerBoardColumn from './CustomerBoardColumn'
import Loader from './common/Loader'
import { useModal } from '../hooks/modal'
import StudentCreate from './StudentCreate'


function changeCustomerPositionAndColumn(
    customers,
    customerId,
    source,
    destination,
) {
    const sourcePosition = source.index + 1
    const destinationPosition = destination.index + 1

    return customers.map((customer) => {
        const isStateChanged = String(source.droppableId) !== String(destination.droppableId)
        const isMovedUp = sourcePosition < destinationPosition && !isStateChanged
        const isMovedDown = sourcePosition > destinationPosition && !isStateChanged && !isMovedUp
        const positionGtOldPosition = customer.position > sourcePosition
        const positionLtOldPosition = customer.position < sourcePosition
        const srcStateChanged = String(customer.state) === source.droppableId
        const destStateChanged = String(customer.state) === destination.droppableId
        const positionGteNewPosition = customer.position >= destinationPosition
        const positionLteDestPosition = customer.position <= destinationPosition
        const positionGtNewPositionAndStateEState = positionGteNewPosition && destStateChanged

        if (customerId === String(customer.id)) {
            return { ...customer, state: Number(destination.droppableId), position: destinationPosition }
        }

        if ((isStateChanged && positionGtOldPosition && srcStateChanged)
        || (isMovedUp && positionGtOldPosition && positionLteDestPosition && destStateChanged)) {
            return { ...customer, position: customer.position - 1 }
        }

        if ((isStateChanged && positionGtNewPositionAndStateEState)
        || (isMovedDown && positionLtOldPosition && positionGtNewPositionAndStateEState)) {
            return { ...customer, position: customer.position + 1 }
        }

        return customer
    })
}


export default function CustomerBoard({ columns, onUpdate, onReloadStatues, customers, type }) {
    const moveCustomer = usePutRequest()
    const acceptedStatus = find(columns, { type: 'accepted' }) || {}

    const [showCustomerAcceptModal, hideCustomerAcceptModal] = useModal((props) => (
        <StudentCreate
            customer={props.customer}
            onCancel={() => hideCustomerAcceptModal()}
            onSuccess={async () => {
                await onDragEnd({
                    source: props.source,
                    destination: props.destination,
                    draggableId: String(props.customer.id),
                    isStudentCreated: true,
                })
                hideCustomerAcceptModal()
            }} />
    ))

    async function onDragEnd({ source, destination, draggableId: customerId, isStudentCreated = false }) {
        if (!destination) return

        const customer = find(customers.response.results, { id: Number(customerId) })
        if (destination.droppableId === String(acceptedStatus.id) && !isStudentCreated
            && String(source.droppableId) !== String(destination.droppableId) && isEmpty(customer.students)) {
            showCustomerAcceptModal({ customer, source, destination })
        }

        // update status
        const results = changeCustomerPositionAndColumn(customers.response.results, customerId, source, destination)
        customers.setResponse({ results })
        const data = { position: destination.index + 1, state: Number(destination.droppableId) }
        await moveCustomer.request({ url: CUSTOMER_MOVE.replace('{id}', customerId), data })
        onReloadStatues()
    }

    async function reloadCustomers(data) {
        onReloadStatues()
        customers.setResponse(data)
    }

    async function onDelete(customerId) {
        onReloadStatues()
        const results = customers.response.results.filter((customer) => customer.id !== customerId)
        // eslint-disable-next-line no-param-reassign
        customers.setResponse({ count: customers.response.count -= 1, results })
    }


    return (
        <div style={{ overflow: 'auto', height: 'calc(100vh - 12rem)' }}>
            <DragDropContext onDragEnd={onDragEnd}>
                <div style={{ display: 'grid', gap: '1rem', gridAutoFlow: 'column', gridAutoColumns: 'minmax(14rem, 1fr)' }}>
                    {columns.map((column, index) => (
                        <div key={column.id}>
                            <CustomerBoardColumn
                                customers={customers}
                                index={index}
                                columnKey={column.id}
                                showLabels={type === ''}
                                type={type}
                                customersAll={customers}
                                column={column}
                                onDelete={onDelete}
                                onCreate={reloadCustomers}
                                onUpdate={onUpdate}
                                lineColor={column.color} />
                        </div>
                    ))}
                </div>
            </DragDropContext>

            <Loader show={customers.loading} center large />

            <div ref={customers.ref} className="has-text-grey-light is-italic has-text-centered">
                {!customers.hasMore && !customers.loading && customers.length !== 0 ? 'Загрузили всех студентов' : ''}
            </div>
        </div>
    )
}
