import { Form, Formik } from 'formik'
import find from 'lodash/find'
import map from 'lodash/map'
import React from 'react'
import { required } from '../utils/validators'
import { useLoad } from '../hooks/request'
import { GROUPS_LIST } from '../urls'
import Input from './common/Input'
import Button from './common/Button'
import Select from './common/Select'


export default function StudentForm({ loading, onSubmit, onCancel, customer }) {
    const groups = useLoad({ url: GROUPS_LIST })
    const groupsIds = map(customer.students, 'group.id')
    const groupOptions = groups.response
        ? groups.response.results.filter((group) => !groupsIds.includes(group.id))
        : []
    function onGroupChange(groupId, setFieldValue) {
        const group = find(groups.response.results, { id: parseInt(groupId, 10) })
        setFieldValue('group', groupId)
        setFieldValue('price', group ? group.price : '')
    }

    return (
        <Formik initialValues={{ price: '', group: '' }} onSubmit={onSubmit}>
            {({ setFieldValue }) => (
                <Form>
                    <Select
                        empty
                        name="group"
                        options={groupOptions}
                        label="Группа"
                        validate={required}
                        onChange={({ target }) => onGroupChange(target.value, setFieldValue)}
                        loading={groups.loading} />

                    <Input
                        name="price"
                        label="Стоимость"
                        placeholder="Стоимость месячного абонемента"
                        disabled={loading}
                        validate={required}
                        type="number" />

                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
